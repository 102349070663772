<template>
  <form id="frm_action" class="available-from">
    <div class="p-formgrid">
      <Toolbar class="fixed-bottom-toolbar div-button css-button-top-right">
        <template slot="right">
          <Button
              label="Back"
              @click="backToList()"
              id="back"
              class="main-style-button"
          />
        </template>
      </Toolbar>
      <div class="col-6">
        <div class="p-card-field">
          <label class="p-col-fixed txt-right">Type</label>
          <div class="p-col">
            <InputText type="text" class="" v-model="type_name" :disabled="true" placeholder="Input"/>
          </div>
        </div>
        <div class="p-card-field" v-if="mode == 'edit'">
          <label class="p-col-fixed txt-right" :class="`${status=='ST001' ? 'txt-required' : ''}`">Status</label>
          <div class="p-col">
            <v-select :disabled="status != 'ST001'" :clearable="false" title="Status" id="available-selectmulti" placeholder="Choose" v-model="selStatus" :options="$constants.AVAILABLE_STATUS[type]" label="label">
              <span slot="no-options" @click="$refs.select.open = false">
                Empty data
              </span>
            </v-select>
            <input class="input_tmp_validator" id="status">
          </div>
        </div>
        <div class="p-card-field" v-if="mode == 'edit'">
          <label class="p-col-fixed txt-right">Property ID</label>
          <div class="p-col">
            <InputText type="text" class="" v-model="property_code" :disabled="true" placeholder="Input"/>
          </div>
        </div>
        <div class="p-card-field">
          <label class="p-col-fixed txt-right">Property</label>
          <div class="p-col">
            <InputText type="text" class="" v-model="property_name" :disabled="true" placeholder="Input"/>
          </div>
        </div>
        <div class="p-card-field">
          <label class="p-col-fixed txt-right">Owner</label>
          <div class="p-col">
            <InputText type="text" class="" v-model="owner_name" :disabled="true"/>
          </div>
        </div>
        <div class="p-card-field">
          <label class="p-col-fixed txt-right">Week</label>
          <div class="p-col">
            <InputText type="text" class="" v-model="week_name" :disabled="true" placeholder="Input"/>
          </div>
        </div>
        <div class="p-card-field" v-if="type == 2">
          <label class="p-col-fixed txt-right" :class="`${status=='ST001' ? 'txt-required' : ''}`">The weeks, the owner wants to exchange with</label>
          <div class="p-col">
            <v-select :multiple="true" :clearable="true" title="Week" class="" placeholder="Choose" v-model="selWeek" :options="listWeek" >
              <span slot="no-options" @click="$refs.select.open = false">
                Empty data
              </span>
              <template slot="option" slot-scope="option">
                  {{ `Week ${getWeekDate(option.visit_start)} (${getFilterDate(option.visit_start)} - ${getFilterDate(option.visit_end)})`}}
              </template>
              
              <template slot="selected-option" slot-scope="option">
                  {{ `Week ${getWeekDate(option.visit_start)} (${getFilterDate(option.visit_start)} - ${getFilterDate(option.visit_end)})`}}
              </template>
            </v-select>
            <div class="mt-2 d-flex flex-wrap" v-show="selWeek.length > 0">
              <span class="d-flex chip-box m-2 justify-content-between align-items-center" v-for="(item, index) in selWeek" :key="`chip-${index}`">
                <!-- <Chip class="m-2 ml-0" :label="" removable removeIconClass="pi pi-times" @remove="removeChip(index)"/> -->
                <span>{{`Week ${getWeekDate(item.visit_start)} (${getFilterDate(item.visit_start)} - ${getFilterDate(item.visit_end)})`}}</span>
                <span class="clear" @click="removeChip(index)"><i class="pi pi-times"></i></span>
              </span>
            </div>
            <input class="input_tmp_validator" id="week_arr">
          </div>
        </div>
        <div class="p-card-field" v-if="mode == 'edit' && type == 3">
          <label class="p-col-fixed txt-right" :class="`${status=='ST001' ? 'txt-required' : ''}`">Price per day</label>
          <div class="p-col">
            <div class="p-inputgroup">
              <InputNumber id="price" v-model="price" :disabled="status!='ST001'" :minFractionDigits="2"/>
              <span class="p-inputgroup-addon">EUR</span>
            </div>
          </div>
        </div>
        
        <div class="p-card-field" v-if="mode == 'edit' && type == 3">
          <label class="p-col-fixed txt-right" :class="`${status=='ST001' ? 'txt-required' : ''}`">Deposit</label>
          <div class="p-col">
            <div class="p-inputgroup">
              <InputNumber id="deposit" v-model="deposit" :disabled="status!='ST001'" :minFractionDigits="2"/>
              <span class="p-inputgroup-addon">EUR</span>
            </div>
          </div>
        </div>
        
        <div class="p-card-field" v-if="mode == 'edit'">
          <label class="p-col-fixed txt-right">Note</label>
          <div class="p-col">
            <Textarea maxLength="2000" rows="2" cols="30" :autoResize="true" v-model="note"/>
          </div>
        </div>
        
        <div class="p-card-field" v-if="mode == 'edit' && type == 2">
          <label class="p-col-fixed txt-right txt-required">Contact name</label>
          <div class="p-col">
            <InputText v-model="contact_name" id="contact_name" placeholder="Input"/>
          </div>
        </div>
        
        <div class="p-card-field" v-if="mode == 'edit' && type == 2">
          <label class="p-col-fixed txt-right txt-required">Phone number</label>
          <div class="p-col">
            <div class="style-box-phone ml-1">
              <Dropdown  v-model="contact_prefix_phone" :options="list_phone_code" optionLabel="prefix_phone" optionValue="prefix_phone"/>
              <InputText type="number" id="contact_phone" class="" v-model="contact_phone"  placeholder="Input"/>
            </div>
            <input class="input_tmp_validator" id="prefix_phone">
          </div>
        </div>
        
        <div class="p-card-field" v-if="mode == 'edit'">
          <div class="pl-2 d-flex align-items-center">
            <Checkbox id="my_property" v-model="my_property" :binary="true" />
            <label class="m-0 ml-3" for="my_property">Only for owner's property</label>
          </div>
          <div class="pl-5" v-if="my_property">
            <label class="p-col-fixed txt-right">The owner want to {{ type == 3 ? 'rent out' : 'exchange'}} with</label>
            <div class="p-col-12">
              <v-select :clearable="false" title="Owner property" class="" @search="SearchOwnerProperty" placeholder="Choose" v-model="selOwnerProperty" :options="listOwnerProperty" label="fullname"  >
                <span slot="no-options" @click="$refs.select.open = false">
                  Empty data
                </span>
              </v-select>
            </div>
          </div>
        </div>
        <div class="p-card-field" v-if="mode == 'edit'">
          <div class="pl-2 d-flex align-items-center">
            <Checkbox id="active" v-model="active" :binary="true" />
            <label class="m-0 ml-3" for="active">Active</label>
          </div>
        </div>
        
        <div class="p-card-field mt-3" v-if="mode == 'edit'">
          <h4>RESPONSE ({{response.length}})</h4>
        </div>
      </div>
      <div class="col-12" v-if="type == 3">
        <DataTable :loading="loadResponse" :value="response" :key="`type-3-${updateDatatable}`">
          <Column field="no" header="No." :headerStyle="{width: '5em'}">
            <template #body="slotProps">
              {{slotProps.index+1}}
            </template>
          </Column>
          <Column field="avatar" header="Avatar">
            <template #body="slotProps">
              <div style="width: 100%; height: 80px;">
                <img style="width:100%; height:100%" :src="slotProps.data.user.avatar.obj_file.url" v-if="slotProps.data.user && slotProps.data.user.avatar && slotProps.data.user.avatar.obj_file" />
              </div>
            </template>
          </Column>
          <Column field="fullname" header="Full name">
            <template #body="slotProps">
              <a class="txt-link" :href="`/admin/list-${slotProps.data.user.role == 'renter' ? 'rent' : 'users'}/edit/${slotProps.data.user.id}`">{{slotProps.data.user.fullname}}</a>
            </template>
          </Column>
          <Column field="email" header="Email">
          <template #body="slotProps">
              {{slotProps.data.user.email}}
            </template>
          </Column>
          <Column field="stay" header="Stay from - to">
            <template #body="slotProps">
              <span v-if="slotProps.data.visit">{{`${getFilterDate(slotProps.data.visit.visit_start)} - ${getFilterDate(slotProps.data.visit.visit_end)}`}}</span>
            </template>
          </Column>
          <Column field="arrival" header="Arrival time">
            <template #body="slotProps">
              {{getFilterDateTimeNotTimeZone(slotProps.data.arrival)}}
            </template>
          </Column>
          <Column field="departure" header="Departure time">
            <template #body="slotProps">
              {{getFilterDateTimeNotTimeZone(slotProps.data.departure)}}
            </template>
          </Column>
          <Column field="created_at" header="Created date">
            <template #body="slotProps">
              {{getFilterDateTime(slotProps.data.created_at)}}
            </template>
          </Column>
          <Column field="status" header="Result">
            <template #body="slotProps">
              <b v-html="filterStatus(slotProps.data.status)"></b>
            </template>
          </Column>
          <Column field="update_name" header="Updated by">
            <template #body="slotProps">
              <span v-if="slotProps.data.update_user">{{slotProps.data.update_user.fullname}}</span>
            </template>
          </Column>
          <Column field="updated_at" header="Updated date">
            <template #body="slotProps">
              {{getFilterDateTime(slotProps.data.updated_at)}}
            </template>
          </Column>
          <Column headerClass="txt-center" bodyClass="txt-center">
            <template #header>
              <Button icon="pi pi-plus" class="p-button-success" @click="addResponse()" v-if="status == 'ST001'"/>
            </template>
            <template #body="slotProps">
              <Button icon="pi pi-check" v-if="status == 'ST001' && slotProps.data.status == 'ST001'"  @click="showPopupAccept(slotProps.index, 'rent')" />
              <Button icon="pi pi-times" v-if="slotProps.data.status == 'ST002'" class="p-button-danger"  @click="cancelResponse(slotProps.index)" />
            </template>
          </Column>
          <template #empty>
              <div class="txt-center">No records found.</div>
          </template>
        </DataTable>
      </div>
      <div class="col-12" v-if="type == 2">
        <DataTable :loading="loadResponse" :value="response" :key="`type-2-${updateDatatable}`">
          <Column field="no" header="No." :headerStyle="{width: '5em'}">
            <template #body="slotProps">
              {{slotProps.index+1}}
            </template>
          </Column>
          <Column field="property" header="Property">
            <template #body="slotProps">
              {{slotProps.data.property.name}}
            </template>
          </Column>
          <Column field="address" header="Address">
            <template #body="slotProps">
              {{slotProps.data.property.address}}
            </template>
          </Column>
          <Column field="owner" header="Owner">
            <template #body="slotProps">
              <a class="txt-link" :href="`/admin/list-${slotProps.data.user.role == 'renter' ? 'rent' : 'users'}/edit/${slotProps.data.user.id}`">{{slotProps.data.user.fullname}}</a>
            </template>
          </Column>
          <Column field="email" header="Email">
            <template #body="slotProps">
              {{slotProps.data.user.email}}
            </template>
          </Column>
          <Column field="week" header="Week">
            <template #body="slotProps">
              <span v-if="slotProps.data.visit">{{`Week ${slotProps.data.visit.week_number} (${getFilterDate(slotProps.data.visit.visit_start)} - ${getFilterDate(slotProps.data.visit.visit_end)})`}}</span>
            </template>
          </Column>
          <Column field="created_at" header="Created date">
            <template #body="slotProps">
              {{getFilterDateTime(slotProps.data.created_at)}}
            </template>
          </Column>
          <Column field="status" header="Result">
            <template #body="slotProps">
              <b v-html="filterStatus(slotProps.data.status)"></b>
            </template>
          </Column>
          <Column field="update_name" header="Updated by">
            <template #body="slotProps">
              <span v-if="slotProps.data.update_user">{{slotProps.data.update_user.fullname}}</span>
            </template>
          </Column>
          <Column field="updated_at" header="Updated date">
            <template #body="slotProps">
              {{getFilterDateTime(slotProps.data.updated_at)}}
            </template>
          </Column>
          <Column header="Action" headerClass="txt-center" bodyClass="txt-center">
            <template #body="slotProps">
              <Button icon="pi pi-check" v-if="status == 'ST001' && slotProps.data.status == 'ST001'"  @click="showPopupAccept(slotProps.index, 'exchange')" />
              <Button icon="pi pi-times" v-if="slotProps.data.status == 'ST002'" class="p-button-danger"  @click="cancelResponse(slotProps.index)" />
            </template>
          </Column>
          <template #empty>
              <div class="txt-center">No records found.</div>
          </template>
        </DataTable>
      </div>
      <Toolbar class="fixed-bottom-toolbar div-button">
        <template slot="left">
          <Button
              v-if="status == 'ST001' && type == 3"
              label="Cancel request"
              @click="cancelAvailable()"
              class="p-button-secondary"
          />
        </template>
        <template slot="right">
          <Button
              :label="$constants.TEXT_BUTTON.BACK"
              @click="backToList()"
              class="p-button-outlined"
              id="back"
          />
          <Button
              v-if="mode == 'edit'"
              :label="$constants.TEXT_BUTTON.EDIT"
              @click="saveData()"
              class="main-style-button"
          />
          <Button
              v-else
              :label="$constants.TEXT_BUTTON.ADD"
              @click="saveData()"
              class="main-style-button"
          />
        </template>
      </Toolbar>
      <Dialog header="Confirmation" :visible.sync="popupCancelAvailable" :style="{width: '50vw'}" >
        Are you cancel this request?
        <template #footer>
            <Button label="Cancel" icon="pi pi-times" class="p-button-secondary" @click="popupCancelAvailable = false"/>
            <Button label="Confirm" icon="pi pi-check" @click="confirmCancelAvailable"/>
        </template>
      </Dialog>
      <Dialog header="Confirmation" :visible.sync="popupConfirmAccept" :style="{width: '50vw'}" >
        Are you accept this request?
        <template #footer>
            <Button label="Cancel" icon="pi pi-times" class="p-button-secondary" @click="popupConfirmAccept = false"/>
            <Button label="Confirm" icon="pi pi-check" @click="acceptResponse"/>
        </template>
      </Dialog>
      <!-- position="right"  -->
      <Dialog header="Add new response" :visible.sync="showPopupAdd" :style="{width: '60vw'}" >
        <form id="frm_action_add">
            <div>
               <!-- <div class="row">  -->
                <!-- <div class="mb-4"> -->
                  <label class="p-col-fixed txt-right txt-required">Choose a user/ renter</label>
                  <div class="p-col">
                    <!-- position: absolute; bottom: 20%; -->
                      <v-select style=""  :clearable="false" :getOptionLabel="filterUser" :searchable="true" title="Owner/ renter" class="w-100  mb-4" @search="searchListUser" placeholder="Choose" v-model="selUser" :options="listUser">
                          <span slot="no-options" @click="$refs.select.open = false">
                              Empty data
                          </span>
                          <template
                            #selected-option-container="{option}"
                          >
                            <div class="vs__selected txt-ellipsis">
                              {{ getLabelListUser(option) }}
                            </div>
                          </template>
                          <template #option="option">
                              {{ getLabelListUser(option) }}
                          </template>
                          
                          <template #selected-option="option">
                              {{ getLabelListUser(option) }}
                          </template>
                      </v-select>
                      <input class="input_tmp_validator" id="choose_user">
                  </div>
                <!-- </div> -->
                <!-- </div> -->

 <div class="row" v-if="type==3">
                <!-- <div class="mb-4"> -->
                 <div class="col-6">
                  <label class="p-col-fixed txt-right txt-required">Arrival time</label>
                  <div class="p-col">
                     <Calendar
                  
                  :showOnFocus="false"
                  dateFormat="dd/mm/yy"
                  id="end_booking_date"
                  :showIcon="true"
                  appendTo="body"
                  :showClear="true"
                  :manualInput="true"
                  v-model="arrival"
                  placeholder="Choose"
                  :showTime="true"
                  :showSeconds="false"
                 
                />
                  </div>
                  </div>

                  <div class="col-6">
                  <label class="p-col-fixed txt-right txt-required">Departure time</label>
                  <div class="p-col">
                     <Calendar
                  
                  :showOnFocus="false"
                  dateFormat="dd/mm/yy"
                  id="end_booking_date"
                  :showIcon="true"
                  appendTo="body"
                  :showClear="true"
                  :manualInput="true"
                  v-model="departure"
                  placeholder="Choose"
                  :showTime="true"
                  :showSeconds="false"
                 
                />
                  </div>
                  </div>
                  <!--  @clear-click="change_rent_pick_up_date($event)"
                  @date-select="change_rent_pick_up_date($event)" -->
                </div>
                <!-- </div> -->

            </div>
            
        </form>
        <template #footer>
            <Button label="Cancel" icon="pi pi-times" class="p-button-secondary" @click="showPopupAdd = false"/>
            <Button label="Confirm" icon="pi pi-check" @click="confirmPopupAdd"/>
        </template>
    </Dialog>
    </div>
  </form>
</template>
<script>
import DataServices from '@/core/DataServices';
import moment from "moment-timezone";
import ApiRepository from "@/core/ApiRepository";
export default {
  components: {
  },
  props: {
    mode: String,
    model: Object,
    users: Array
  },
  data() {
    return {
      loadResponse: false,
      contact_prefix_phone: null,
      list_phone_code: [
        {
          prefix_phone:"+84",
        },
        {
          prefix_phone:"+45",
        }
      ],
      responseStatus: [
        {value: "ST001", label: "", color: "#000000"},
        {value: "ST002", label: "Accepted", color: "#28A745"},
        {value: "ST003", label: "Cancelled", color: "#F5564E"},
      ],
      loadUser: false,
      showPopupAdd:false,
      selUser: null,
      listUser: [],
      listUserAll: [],
      priceOld:0,
      is_admin_update_price: false,
      total: 0,
      selStatus: null,
      active: true,
      my_property: false,
      owner_name: null,
      type_name: 'Rent request',
      property_id: null,
      property_name: null,
      property_code: null,
      property_address: null,
      week_name: null,
      type: 3,
      note: null,
      status: 'ST001',
      price: 0,
      deposit: 0,
      response: [],
      time_zone_local : moment.tz.guess(true),
      selOwnerProperty: null,
      listOwnerProperty: [],
      listOwnerPropertyAll: [],
      contact_name: null,
      contact_phone: null,
      listWeek: [],
      selWeek: [],
      uid: null,
      dataConfirmAccept: null,
      popupConfirmAccept: false,
      updateDatatable: 0,
      popupCancelAvailable: false,
      responseTmp: null,
      arrival: null,
      departure: null,
      loadEdit: 0,
      componentKey: 0
    }
  },
  computed: {
  },
  watch: {
    async "selUser"() {
      if (this.selUser?.id) {
        var fileData = await this.$apollo.query({
            query: DataServices.getList('relationship_file', {
            "fields" : `id obj_file{url}`
          }),
          variables: {
            where_key: {
              obj_id: {'_eq': this.selUser.id},
              type: {'_eq': 'USER_AVATAR'}
            }
          },
          fetchPolicy: "network-only"
        })
        var avatar = fileData?.data?.relationship_file[0];
        this.selUser.avatar = avatar;
      }
    },
    async "my_property"() {
      await this.getListOwnerProperty();
      // if (this.my_property) {
      //   await this.changeResponse();
      // } else {
      //   // if (this.selOwnerProperty?.id) {
      //   //   let indexOf = this.response.indexOf(this.response.filter(f => f?.user?.id == this.selOwnerProperty?.id)[0]);
      //   //   if (indexOf != -1) {
      //   //     this.response.splice(indexOf, 1);
      //   //   }
      //   // }
      //   if (this.responseTmp?.user) {
      //     let indexOf = this.response.indexOf(this.responseTmp);
      //     if (indexOf != -1) {
      //       this.response.splice(indexOf, 1);
      //     }
      //   }
      // }
    },
    "price" () {
      if (this.price != this.priceOld && this.price != this.model.price) {
        this.is_admin_update_price = true;
      }
    },
    async "selOwnerProperty"() {
      if (this.loadEdit == 1) {
        this.loadEdit = 0;
        return;
      }
      // await this.changeResponse();
    },
  },
  async mounted() {
    this.response = [];
    if (this.model !== undefined && Object.keys(this.model).length > 0) {
      this.loadEdit = 1;
      this.type = this.model.type;
      this.uid = this.model.created_by;
      this.response = this.model.response;
      // for (let i = 0; i < this.response.length; i++) {
      //   //check empty
      //    if (!this.$commonFuction.isEmpty(this.response[i].arrival)){
      //      this.response[i].arrival = moment.tz(this.response[i].arrival, this.time_zone_local).format("YYYY-MM-DD HH:mm:ss");
      //    }
      //    //
      //     if (!this.$commonFuction.isEmpty(this.response[i].departure)){
      //       this.response[i].departure = moment(this.response[i].departure).tz(this.response[i].arrival, this.time_zone_local).format("YYYY-MM-DD HH:mm:ss");
      //     }
      // }
      console.log("this.response111", this.response);
      this.selWeek = this.model.week;
      this.my_property = this.model.my_property;
      this.property_id = this.model.property.id;
      this.property_name = this.model.property.name;
      this.property_code = this.model.property.code;
      this.property_address = this.model.property.address;
      this.contact_name = this.model.contact_name;
      this.contact_prefix_phone = this.model.contact_prefix_phone;
      this.contact_phone = this.model.contact_phone;
      this.type_name = [{value: 2, label: 'Exchange request'}, {value: 3, label: 'Rent request'}].filter(f => f.value == this.model.type)[0]?.label;
      this.selStatus = this.$constants.AVAILABLE_STATUS[this.type].filter(f => f.value == this.model.status)[0];
      this.note = this.model.note;
      this.owner_name = this.model?.create_user?.fullname;
      this.status = this.model.status;
      this.price = this.model.price;
      this.priceOld = this.model.price;
      this.deposit = this.model.deposit;
      this.week_name = `Week ${this.model.visit.week_number} (${this.getFilterDate(this.model.visit.visit_start)} - ${this.getFilterDate(this.model.visit.visit_end)})`
      this.selOwnerProperty = this.model.owner;
      this.updateDatatable++;
    }
    if (this.type == 2) {
      await this.getListWeek();
    }
    if (this.type == 3) {
      await this.getListUser();
    }
  },
  methods: {
    async changeResponse() {
      if (this.selOwnerProperty?.id) {
        if (this.responseTmp?.user) {
          let indexOf = this.response.indexOf(this.responseTmp);
          if (indexOf != -1) {
            this.response.splice(indexOf, 1);
          }
        }
        let checkRes = this.response.filter(f => f?.user?.id == this.selOwnerProperty?.id);
        console.log("checkRescheckRes", this.response);
        console.log("checkRescheckRes", checkRes);
        if (checkRes.length > 0) {
          return;
        }
        this.loadResponse = true;
        var fileData = await this.$apollo.query({
            query: DataServices.getList('relationship_file', {
            "fields" : `id obj_file{url}`
          }),
          variables: {
            where_key: {
              obj_id: {'_eq': this.selOwnerProperty.id},
              type: {'_eq': 'USER_AVATAR'}
            }
          },
          fetchPolicy: "network-only"
        })
        var avatar = fileData?.data?.relationship_file[0];
        this.selOwnerProperty.avatar = avatar;
        this.responseTmp = {
          user: this.selOwnerProperty,
          owner: this.selOwnerProperty,
          property: {
            id: this.property_id,
            address: this.property_address,
            name: this.property_name
          },
          is_new: true,
          status: "ST001",
          created_at: new Date(),
          updated_at: new Date(),
          updated_by: this.$store.getters.user
        }
        this.response.push(this.responseTmp)
        this.loadResponse = false;
      }
    },
    getLabelListUser(data) {
      let txt = `${data.fullname} - ${data.email}`;
      if (data?.arr_part && data.arr_part.length > 0) {
        let arr_txt = [];
        data.arr_part.map(m => {
          arr_txt.push(m?.obj_property.name);
        })
        txt += `(${arr_txt.join(', ')})`;
      }
      return txt;
    },
    filterStatus(data) {
      var filter = this.responseStatus.filter(f => f.value == data);
      return `<span style="color: ${filter[0]?.color}">${filter[0]?.label}</span>`;
    },
    async addResponse() {
      this.showPopupAdd = true;
      if(this.type==3){
        //this.week_name = `Week ${this.model.visit.week_number} (${this.getFilterDate(this.model.visit.visit_start)} - ${this.getFilterDate(this.model.visit.visit_end)})`
       //FORMAT_DATE_SEVER
       this.$constants.FORMAT_DATE_SEVER
       var visit_start =  moment.utc(this.model.visit.visit_start).format(this.$constants.FORMAT_DATE_SEVER);
       var visit_end =  moment.utc(this.model.visit.visit_end).format(this.$constants.FORMAT_DATE_SEVER);
        this.arrival = new Date(`${visit_start} 14:00:00`);
        this.departure = new Date(`${visit_end} 12:00:00`);
      }
    },
    confirmPopupAdd() {
      let choose_user_add = document.getElementById("choose_user");
      if (this.$commonFuction.isEmptyObject(this.selUser)){
        alert("Please choose user/renter!");
        return;
        //choose_user_add.setCustomValidity("Please choose user/renter!");
      }else {
        let checkRes = this.response.filter(f => f?.user?.id == this.selUser?.id);
        if (checkRes.length > 0) {
          choose_user_add.setCustomValidity("This user/renter already exists in the list");
          alert("This user/renter already exists in the list");
          return;
        } 
        // else {
        //   choose_user_add.setCustomValidity("");
        // }
      }
      if (this.$commonFuction.isEmpty(this.arrival)){
        alert("Please choose arrival time!");
        return;
      }
      if (this.$commonFuction.isEmpty(this.departure)){
        alert("Please choose departure time!");
        return;
      }
      var arrival_check =moment(this.arrival).format("YYYYMMDDHHmm");
      var departure_check =moment(this.departure).format("YYYYMMDDHHmm");
      if (parseInt(arrival_check) > parseInt(departure_check)){
        alert("Arrival time must be less than departure time!");
        return;
      }
      // var inpObj = document.getElementById("frm_action_add");
      // if (!inpObj.checkValidity()){
      //   inpObj.reportValidity();
      //   return true;
      // }
      console.log("this.response", this.response);
      //+00:00
      var dataRes = {
        user: this.selUser,
        is_new: true,
        status: "ST001",
       // arrival: this.arrival,
       // departure: this.departure,
        created_at: new Date(),
        updated_at: new Date(),
        updated_by: this.$store.getters.user
      }
      if(this.type==3){
        console.log("this.arrival", this.arrival);
         dataRes.arrival = moment(this.arrival).format("YYYY-MM-DD HH:mm:ss+00:00");
          console.log("this.arrival", dataRes.arrival);
         dataRes.departure = moment(this.departure).format("YYYY-MM-DD HH:mm:ss+00:00");
        // dataRes.arrival = moment(this.arrival).format("YYYY-MM-DD HH:mm:ss+00:00");
        // dataRes.departure = moment(this.departure).format("YYYY-MM-DD HH:mm:ss+00:00");
      //console
        // dataRes.arrival = this.arrival
        // dataRes.departure = this.departure
      }
      this.response.push(dataRes)
      this.showPopupAdd = false;
      this.selUser = null;
      this.arrival = null;
      this.departure = null;
    },
    cancelAvailable() {
      this.popupCancelAvailable = true;
    },
    async confirmCancelAvailable() {
      // let variables = {
      //   'data_update': {
      //     'status': "ST003"
      //   },
      //   'where_key': {
      //     'id': {'_eq': this.model.id}
      //   }
      // };
      // this.$apollo.mutate({
      //   mutation: DataServices.updateData('available'),
      //   variables: variables
      // }).then(() => {
      //   this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Cancel request successfully', life: 3000 })
      // })
      
      const headers = {
        "Authorization": "Bearer "+(await this.$AuthenticationApp.getToken()),
      };
      var dataUpd = {
        available_id: this.model.id
      }
      var url = '_api/property/cancel_available_admin';
      ApiRepository.post(url, dataUpd, {headers}).then(() => {
        setTimeout(() => {
          this.$emit('loadData');
          this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Cancel request successfully', life: 3000 })
        }, 200);
      })
      this.popupCancelAvailable = false;
      // this.$emit("loadData");
    },
    showPopupAccept(index, type) {
      this.response[index].type = type;
      this.dataConfirmAccept = this.response[index];
      this.popupConfirmAccept = true;
    },
    async acceptResponse() {
      if (this.dataConfirmAccept?.is_new) {
        let list = {
          available_id: this.model.id,
          status: this.dataConfirmAccept.status,
          uid: this.dataConfirmAccept.user.id,
          updated_by: this.dataConfirmAccept?.updated_by || this.$store.getters.user,
          created_by: this.dataConfirmAccept?.created_by || this.$store.getters.user,
          updated_at: this.dataConfirmAccept?.updated_at || new Date(),
          created_at: this.dataConfirmAccept?.created_at || new Date()
        }
        await this.$apollo.mutate({
            mutation: DataServices.insertData('available_reponse'),
            variables: {
              objects: list
            }
        }).then(async (res) => {
          this.dataConfirmAccept.id = await res.data[Object.keys(res.data)]?.returning[0]?.id;
        })
        delete this.dataConfirmAccept.is_new;
      }
      const headers = {
        "Authorization": "Bearer "+(await this.$AuthenticationApp.getToken()),
      };
      var dataUpd = {
        available_response_id: this.dataConfirmAccept.id
      }
      var url = this.dataConfirmAccept.type == 'exchange' ? '_api/property/accept_response_exchange_admin' : '_api/property/accept_response_rent_admin';
      ApiRepository.post(url, dataUpd, {headers}).then(() => {
        setTimeout(() => {
          this.$emit('loadData');
        }, 200);
      })
      this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Accept request successfully', life: 3000 })
      this.popupConfirmAccept = false;
      this.dataConfirmAccept = null;
    },
    async cancelResponse(index) {
      // this.response[index].status = "ST001";
      // let variables = {
      //   'data_update': {
      //     'status': this.response[index].status
      //   },
      //   'where_key': {
      //     'id': {'_eq': this.response[index]?.id}
      //   }
      // };
      // await this.$apollo.mutate({
      //   mutation: DataServices.updateData('available_reponse'),
      //   variables: variables
      // })
      
      // let variables_ = {
      //   'data_update': {
      //     'status': "ST001"
      //   },
      //   'where_key': {
      //     'id': {'_eq': this.model.id}
      //   }
      // };
      // await this.$apollo.mutate({
      //   mutation: DataServices.updateData('available'),
      //   variables: variables_
      // }).then(() => {
      //   setTimeout(() => {
      //     this.$emit('loadData');
      //   }, 200);
      // });
      const headers = {
        "Authorization": "Bearer "+(await this.$AuthenticationApp.getToken()),
      };
      var dataUpd = {
        available_response_id: this.response[index]?.id
      }
      var url = '_api/property/cancel_response_admin';
      ApiRepository.post(url, dataUpd, {headers}).then(() => {
        setTimeout(() => {
          this.$emit('loadData');
          this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Cancel response successfully', life: 3000 })
        }, 200);
      })
    },
    removeChip(index) {
      this.selWeek.splice(index, 1);
    },
    async getListWeek() {
      let startDate = moment().startOf('month');
      let endDate = moment(moment().startOf('month').add(1, "years").endOf('month'));
      let diff_date = endDate.diff(startDate, 'days');
      console.log("startDate", startDate);
      console.log("endDate", endDate);
      console.log("diff_date", diff_date);
      this.listWeek = [];
      let days = 7;
      this.listWeek.push({visit_start: moment(moment(startDate).startOf('week').format('YYYY/MM/DD HH:mm:ss')), visit_end: moment(moment(startDate).endOf('week').format('YYYY/MM/DD HH:mm:ss'))});
      for(let i=1; i <= diff_date; i++) {
        if(i%days==0 && i != diff_date) {
          let start_d = moment(moment(startDate).add((i+1), 'days').startOf('week').format('YYYY/MM/DD HH:mm:ss'));
          this.listWeek.push({visit_start: start_d, visit_end: moment(moment(start_d).endOf('week').format('YYYY/MM/DD HH:mm:ss'))});
        }
      }
    },
    async getListUser() {
      var where_key = {
        role: {_in: ['owner', 'renter']},
        deleted: {_eq: false}
      }
      let {data} = await this.$apollo.query({
        query: DataServices.getList('users',{"fields" : `id fullname role email arr_part{id obj_property{id name}}`}),
        variables: {
          where_key: where_key,
          orderBy:[{created_at : 'desc'}]
        },
        fetchPolicy: "network-only"
      })
      var list = data[Object.keys(data)];
      var list_owner = [];
      for (let i = 0; i < list.length; i++) {
        const e = list[i];
        list_owner.push({id : e.id, fullname :e.fullname, email: e.email, arr_part: e.arr_part, role: e.role});
      }
      this.listUser = list_owner;
      this.listUserAll = list_owner;
    },
    filterUser(option) {
      if (typeof option === 'object') {
        return this.getLabelListUser(option);
      }
      return option;
    },
    async searchListUser(search, loading){
      loading(true);
      if(search !== '' && search !== null){
        var where_key = {
          deleted: {_eq: false},
          role: {_in: ['owner', 'renter']},
          _or: [{
            fullname:{_iregex: this.$commonFuction.search(search)}
          },{
            arr_part: {obj_property: {name:{_iregex: this.$commonFuction.search(search)}}}
          },{
            email:{_iregex: this.$commonFuction.search(search)}
          }]
        }
        let {data} = await this.$apollo.query({
          query: DataServices.getList('users',{"fields" : `id fullname email role arr_part{id obj_property{id name}}`}),
          variables: {
            where_key: where_key,
            orderBy:[{created_at : 'desc'}]
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_owner = [];
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          list_owner.push({id : e.id, fullname :e.fullname, email: e.email, arr_part: e.arr_part, role: e.role});
        }
        this.listUser = list_owner;
        loading(false);
      }else{
        this.listUser = this.listUserAll;
        loading(false);
      }
      return this.listUser;
    },
    async getListOwnerProperty() {
      var where_key = {
        property_id: {_eq: this.property_id}, 
      }
      if (this.uid) {
        where_key.uid = {_neq: this.uid}
      }
      let {data} = await this.$apollo.query({
        query: DataServices.getList('property_part',{"fields" : `id user {id fullname email}`}),
        variables: {
          where_key: where_key,
          orderBy:[{order_booking : 'desc'}]
        },
        fetchPolicy: "network-only"
      })
      var list = data[Object.keys(data)];
      var list_owner = [];
      var checkUser = [];
      for (let i = 0; i < list.length; i++) {
        const e = list[i];
        if(!checkUser.includes(e.user.id)) {
          list_owner.push({id : e.user.id, fullname :e.user.fullname, email: e.user.email});
          checkUser.push(e.user.id);
        }
      }
      this.listOwnerProperty = list_owner;
      this.listOwnerPropertyAll = list_owner;
    },
    async SearchOwnerProperty(search, loading){
      console.log(loading);
      if(search !== '' && search !== null){
        var where_key = {
          property_id: {_eq: this.property_id},
          _or: {
            user:{fullname:{_iregex: this.$commonFuction.search(search)}},
          }
        }
        if (this.uid) {
          where_key.uid = {_neq: this.uid}
        }
        let {data} = await this.$apollo.query({
          query: DataServices.getList('property_part',{"fields" : `id user {id fullname email}`}),
          variables: {
            where_key: where_key,
            orderBy:[{order_booking : 'desc'}]
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_owner = [];
        var checkUser = [];
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          if(!checkUser.includes(e.user.id)) {
            list_owner.push({id : e.user.id, fullname :e.user.fullname, email: e.user.email});
            checkUser.push(e.user.id);
          }
        }
        this.listOwnerProperty = list_owner;
      }else{
        this.listOwnerProperty = this.listOwnerPropertyAll;
      }
    },
    removeProduct(index) {
      this.products.splice(index, 1);
    },
    addProduct() {
      this.products.push({
        product: {price: 0},
        price: 0,
        qty: 0
      })
    },
    getWeekDate(date) {
      if (!this.$commonFuction.isEmpty(date)){
        return moment(date).format("w");
          }else{
        return "";
      }
    },
    getFilterDate(date) {
      if (!this.$commonFuction.isEmpty(date)){
      return moment.tz(date, this.time_zone_local).format("ll");
       }else{
        return "";
      }
    },
    getFilterDateTime(date) {
       if (!this.$commonFuction.isEmpty(date)){
      return moment.tz(date, this.time_zone_local).format("LL HH:mm:ss");
       }else{
        return "";
      }
    },
    getFilterDateTimeNotTimeZone(date) {
      if (!this.$commonFuction.isEmpty(date)){
        
      return moment.tz(date, "UTC").format("LL HH:mm:ss");
      }else{
        return "";
      }
    },
    backToList() {
      this.$emit('back');
    },
    async validateData() {
      this.validationErrors = {};
      let status_add = document.getElementById("status");
      if (this.$commonFuction.isEmptyObject(this.selStatus)){
        status_add.setCustomValidity("Please choose status!");
      }else {
        status_add.setCustomValidity("");
      }
      if (this.type == 2) {
        let list_week_add = document.getElementById("week_arr");
        if (this.selWeek.length < 1){
          list_week_add.setCustomValidity("Please choose week!");
        }else {
          list_week_add.setCustomValidity("");
        }
        let contact_name_add = document.getElementById("contact_name");
        if (!this.contact_name){
          contact_name_add.setCustomValidity("Please input contact name!");
        }else {
          contact_name_add.setCustomValidity("");
        }
        
        let contact_phone_add = document.getElementById("contact_phone");
        if (!this.contact_phone){
          contact_phone_add.setCustomValidity("Please input phone number!");
        }else {
          contact_phone_add.setCustomValidity("");
        }
      } else if (this.type == 3) {
        let price_add = document.getElementById("price");
        if (!this.price || this.price <= 0){
          price_add.setCustomValidity("Please input price per day!");
        }else {
          price_add.setCustomValidity("");
        }
        
        let deposit_add = document.getElementById("deposit");
        if (!this.deposit || this.deposit <= 0){
          deposit_add.setCustomValidity("Please input deposit for renter!");
        }else {
          deposit_add.setCustomValidity("");
        }
      }

      var inpObj = document.getElementById("frm_action");
      if (!inpObj.checkValidity()){
        inpObj.reportValidity();
        return true;
      }
    },

    async saveData() {
      this.$commonFuction.is_loading(true);
      if (await this.validateData()) {
        this.$commonFuction.is_loading(false);
        return
      }
      var params = {
        note: this.note,
        contact_name: this.contact_name,
        contact_phone: this.contact_phone,
        is_admin_update_price: this.is_admin_update_price,
        response: this.response,
        deposit: this.deposit,
        price: this.price,
        active: this.active,
        owner_id: this.selOwnerProperty?.id,
        my_property: this.my_property,
        status: this.selStatus?.value,
        list_week: this.selWeek,
        type: this.type
      }
      console.log('saveData', params)
      this.$emit('save', params)
    },
  },
}
</script>

<style lang="scss">
.p-multiselect {
  width: 450px;
}
.p-multiselect-label:not(.p-placeholder) {
  padding-top: .25rem;
  padding-bottom: .25rem;

}
.category-item-value {
  padding: .25rem .5rem;
  border-radius: 3px;
  display: inline-flex;
  margin-right: .2rem;
  background-color: var(--primary-color);
  color: var(--primary-color-text);
}
.p-multiselect-label-container{
  height: 40px;
}
.p-inputtext{
  margin:  0px 0px 0px 4px;
}

//style form add-edit
@media screen and (min-width: 1001px) {
  .input_distance {
    width: 728px !important;
  }
}
label.p-col-fixed txt-right{
  margin-bottom: 0px!important;
}
div.p-card-field{
  padding: 0px!important;
}
.p-col-fixed {
  padding: 0px !important;
  margin:0px 0px 0px 9px !important;
}
.input_text{
  width: 760px!important;
  height: 48px!important;
}
.txt-right{
  font-weight: bold;
}

.multiselect{
  width: 760px!important;
}
.layout-main{
  background: #00000029;
}

.vs__clear{
  display: none;
}
.input_width{
  width: 760px!important;
  height: 48px!important;
}
#distance{
  width: 1px!important;
  height: 48px!important;
  border-left: 0px;
  border-top: 0px;
  border: 1px solid #C8C8C8;
  border-radius: 0px;
  //border-top: none!important;
  //border: 1px solid rgba(60, 60, 60, 0.26);;
}
.manager-title{
  font-size: 24px!important;
}
.input-dif{
  margin-left: 5px!important;
  border-top: 0px;
}
.p-inputgroup-addon{
  height: 48px ;
}
.txt-right{
  font-weight: bold;
}
.p-col-fixed {
  padding: 0px !important;
  //margin:0px 0px 0px 10px !important;
}
label.p-col-fixed txt-right{
  margin-bottom: 0px!important;
}
div.p-card-field{
  padding: 0px!important;
}
.margin{
  margin-bottom: 12px!important;
}

.border_color{
  border: 1px solid #C8C8C8;
}
.text{
  font-weight: normal!important;
  margin-left: 15px!important;
}
.div_check{
  margin-top: 12px!important;
}


//responsive
@media screen and (max-width: 600px) {
  .input_width{
    width: 100%!important;
  }
  .div-button{
    padding: 8px!important;
  }
  #distance{
    text-align: left!important;
    padding: 6.8px;
    width: 100%!important;
  }
  .p-inputgroup-addon{
    margin-right: 12px;
  }

}

@media screen and (max-width: 1000px) {
  .input_width{
    width: 100%!important;
  }
  .div-button{
    padding: 8px!important;
  }
  .p-inputgroup-addon{
    margin-right: 12px;
  }
  #distance{
    text-align: left!important;
    padding: 6.8px;
  }
}
.p-button-outlined {
  .p-button-label {
    color: #007ad9 !important;
  }
}
.available-from .vs--multiple .vs__selected-options {
  .vs__selected {
    display: none;
  }
}
.chip-box {
  background-color:#F2F3F7;
  color: #000;
  border-radius: 10px;
  padding: 10px;
  font-size: 16px;
  width: fit-content;
  font-weight: bold;
  margin-left: 0 !important;
  .clear {
    cursor: pointer;
    color: #666666;
    margin-left: 10px;
    .pi-times:before {
      font-weight: bold;
      font-size: 12px;
    }
  }
}
.vs__selected.txt-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 650px;
  white-space: nowrap;
}
.style-box-phone{
  border: 1px solid #a6a6a6;
  width: 100%;
  display: flex;
  border-radius: 3px;
  .p-dropdown{
    width: 100px;
    border: 0px;
    box-shadow: none !important;
  }
  .p-focus{
    border: 0px;
    box-shadow: none ;
  }
  .p-dropdown:focus{
    border: 0px;
    box-shadow: none;
  }
  input{
    width: calc(100% - 100px);
    margin-left: 0px;
    border: 0px;
  }
  .p-inputtext:enabled:focus {
    border: 0px;
    box-shadow: none;
  }
}
</style>
